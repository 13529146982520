<template>
  <div style="padding-bottom: 50px;">
    <h4>รายการโพย</h4>

    <DateFilter @submit="search" />

    <div class="row mb-1 align-items-end">
      <div v-if="dateText.start===dateText.end" class="col-8">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
      <div v-else class="col">
        ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
        ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
        <span> ถึง
          <span class="text-primary">{{dateText.end}}</span>
        </span>
      </div>

      <div class="col-4 d-flex justify-content-end">
        <button class="btn btn-sm ml-1" :class="{'btn-primary': showTicketResult=== 'all', 'btn-outline-primary': showTicketResult!== 'all'}" @click="toggleTicketResult('all')">ทั้งหมด</button>
        <button class="btn btn-sm ml-1" :class="{'btn-info': showTicketResult=== 'wait', 'btn-outline-info': showTicketResult!== 'wait'}" @click="toggleTicketResult('wait')">รอผล</button>
        <button class="btn btn-sm ml-1" :class="{'btn-success': showTicketResult=== 'won', 'btn-outline-success': showTicketResult!== 'won'}" @click="toggleTicketResult('won')">บิลได้</button>
        <button class="btn btn-sm ml-1" :class="{'btn-danger': showTicketResult=== 'lose', 'btn-outline-danger': showTicketResult!== 'lose'}" @click="toggleTicketResult('lose')">บิลเสีย</button>
        <button class="btn btn-sm ml-1" :class="{'btn-warning': showTicketResult=== 'canceled', 'btn-outline-warning': showTicketResult!== 'canceled'}" @click="toggleTicketResult('canceled')">บิลยกเลิก</button>
      </div>
    </div>

    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <b-nav card-header tabs class="justify-content-between">
          <div class="d-flex">
            <b-nav-item @click="activeTab='AllTickets'" :active="activeTab==='AllTickets'">รายการทั้งหมด</b-nav-item>
            <b-nav-item @click="activeTab='TicketByType'" :active="activeTab==='TicketByType'">ตามชนิดหวย</b-nav-item>
          </div>

          <b-nav-form>
            <b-form-input v-model="searchText" placeholder="ค้นหาโพย" size="sm" class="mr-1"></b-form-input>
            <b-button type="button" size="sm" variant="info" @click="searchText=''">เคลียร์</b-button>
          </b-nav-form>
        </b-nav>
      </b-card-header>

      <AllTickets v-if="activeTab==='AllTickets'" :items="items" @reload="loadTickets" @search="searchTicket" />
      <TicketByType v-if="activeTab==='TicketByType'" :items="items" @reload="loadTickets" @search="searchTicket" />

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="60"
        :width="60"
        color="#007bff"
        loader="dots"
      />
    </b-card>

    <div class="small font-weight-light pl-2">
      *** หมายเหตุ ***
      <div><i class="fas fa-undo text-warning fa-sm"></i> <span>คืนเงิน</span></div>
      <div><i class="fas fa-times text-danger"></i> <span>ยกเลิก</span></div>
    </div>
  </div>
</template>
<script>
import DateFilter from '@/views/report/components/DateFilter'
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import cryptoRandomString from 'crypto-random-string'
import moment from '@/helpers/moment'
import AllTickets from './ticket/AllTickets'
import TicketByType from './ticket/TicketByType'
import cAlert from '@/helpers/alert'

export default {
  name: 'Tickets',
  components: {
    DateFilter,
    AllTickets,
    TicketByType
  },
  data() {
    return {
      activeTab: 'AllTickets',
      data: null,
      isLoading: true,
      filters: {
        startDate: null,
        endDate: null
      },
      showTicketResult: 'all',
      searchText: ''
    }
  },
  computed: {
    items() {
      return (this.data?.items || []).filter((item)=>{
        return ['Accepted', 'Canceled', 'Refund', 'Processing', 'Completed'].includes(item.status) && item?.summary?.credit
      })
      .filter((item)=>{
        return {
          'all': true,
          'wait': item.isWon===null && item.status !== 'Canceled',
          'won': item.isWon===true && item.status !== 'Canceled',
          'lose': item.isWon===false && item.status !== 'Canceled',
          'canceled': item.status === 'Canceled'
        }[this.showTicketResult] && (!this.searchText || (item.remarks.includes(this.searchText)))
      })
    },
    dateText() {
      if(!this.data)
        return {
          start: null,
          end: null
        }

      return {
        start: moment(this.data.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.data.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      if(!this.data)
        return ''

      return this.data.filters.total || 0
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        ...filters,
        startDate: filters.date.start,
        endDate: filters.date.end,
      }
      this.loadTickets()
    },
    loadTickets() {
      this.isLoading = true
      ReportService.getTicketsDate(this.filters).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดรายการแทงไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    toggleTicketResult(res) {
      this.showTicketResult = res
    },
    searchTicket(remark) {
      this.searchText = remark
    }
  }
}
</script>
